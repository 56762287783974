import React from 'react';
import MuiListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import MuiList from '@material-ui/core/List';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listitem: {
    borderLeft: '0.3rem solid',
    borderColor: theme.palette.primary.light,
  },
  wordbreak: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ListItemCollapse = ({
  documents, isOpen, children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.listitem}>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
        <MuiList component="nav" disablePadding>
          {documents.map(document => [
            <MuiListItem
              button={!!document.link}
              component="a"
              href={document.link}
              target="_blank"
              rel="noopener noreferrer"
              key={document.title + document.link}
            >
              {document.showFileIcon && (
                <MuiListItemIcon>
                  <InsertDriveFileOutlined />
                </MuiListItemIcon>
              )}
              <Typography variant="body2" className={classes.wordbreak}>{document.title}</Typography>
            </MuiListItem>,
          ])}
        </MuiList>
        <Divider />
      </Collapse>
    </div>
  );
};

ListItemCollapse.propTypes = {
  /** List of documents to be displayed in the collapse */
  documents: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    showFileIcon: PropTypes.bool,
  })),
  /** Show/Hide component */
  isOpen: PropTypes.bool,
  children: PropTypes.any,
};

ListItemCollapse.defaultProps = {
  isOpen: false,
  documents: [],
  children: null,
};

export default ListItemCollapse;
