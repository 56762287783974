import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button as MuiButton } from '@material-ui/core';

const useStyles = makeStyles({
  lowercase: {
    textTransform: 'none',
    fontSize: '1rem',
  },
});

const Button = ({
  children, className, ...props
}) => {
  const classes = useStyles();
  return (
    <MuiButton className={clsx(className, classes.lowercase)} {...props}>
      {children}
    </MuiButton>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};
Button.defaultProps = {
  children: undefined,
  className: '',
};

export default Button;
